/**
 * layout
 */

.inflate {
    width: 100% !important;
    height: 100% !important;
}

.center-contents {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-contents-v {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.row {
    display: flex;
    flex-direction: row;
}

.row-align-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.col-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.col {
    display: flex;
    flex-direction: column;
}

.interaction-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}


/**
 * GENERAL
 */

.flex {
    display: flex;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.grow-space {
    flex-grow: 1 !important;
}

.inline-el {
    display: inline-block;
}
