/**
 * DEFINITIONS
 */

$iona-orange: #f59b01;

$iona-pink: #E3144E;
$iona-live-blue: #47A7D8;


$text-color: #39393a;
$heading-grey: #7A7A7A;
$border-grey: #E2E2E2;

$anthracite: #333333;

$accent-yellow: rgb(255, 195, 0);
$indigo: rgb(0, 170, 225);
$indigo-dark: rgb(20, 60, 140);
$red-muted: #b9280a;
$red-banner: #fac6c2;


/**
 * CLASSES
 */
.yellow {
    color: $accent-yellow;
}

.indigo-clr {
    color: $indigo;
}

.indigo-dark-clr {
    color: $indigo-dark;
}

.yellow-bg {
    background: $accent-yellow;
}
