//
@import 'ngx-toastr/toastr';

// dependencies
@import "styles/colors";

// overlays default / custom
@import '@angular/cdk/overlay-prebuilt.css';
@import "styles/overlay";

// layout
@import "styles/spacer";
@import "styles/layout";

// type
@import "styles/type";
@import "styles/fonts";
@import "styles/heading";

// components
@import "styles/input";
@import "styles/boeppel";

@import "styles/components/button";
@import "styles/components/menu";
@import "styles/components/tiles";
@import "styles/components/details";
@import "styles/components/page";
@import "styles/components/tab-selector";
@import "styles/components/links";
@import "styles/components/input";
@import "styles/components/sidebar";
@import "styles/components/icons";
@import "styles/components/provider-logos";

@import "styles/components/iona-input";

@import "styles/components/checkbox";
@import "styles/components/checkbox-alt";

@import "styles/components/tab-bar";

@import "styles/components/scoll-container";

@import "styles/components/date-pickers";

// notifications
@import "styles/components/notifications";

// charts
@import "styles/charts.global";

// utils
@import "styles/utils";

.icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iic {
    position: relative !important;
}


/**
 * general
 */

.toast-container .ngx-toastr {
    box-shadow: none !important;
}

.pointer {
    cursor: pointer;
}


/**
 * SVG ICON BRANCHES
 */
.household-icon-active {
    -webkit-transition: fill 250ms ease;
    -moz-transition: fill 250ms ease;
    -ms-transition: fill 250ms ease;
    -o-transition: fill 250ms ease;
    transition: fill 250ms ease;

    svg {
        .fill-active {
            fill: #1ea2b1;
        }

        .stroke-active {
            stroke: #39393a;
        }
    }
}


