@import "spacer";

.overlay {
    width: 1100px;
    max-width: 1100px;

    max-height: 90vh;

    z-index: 100000;
    position: relative;

    overflow: hidden;
    overflow-y: scroll;

    background: #ffffff;
    //
    //-ms-overflow-style: none;
    //scrollbar-width: none;

    //&::-webkit-scrollbar {
    //    display: none;
    //}

    transition: width 500ms ease, max-width 500ms ease;
}

.overlay.narrow {
    width: 700px;
    max-width: 700px;
}

.overlay.fixed-height {
    height: 900px;
    overflow-y: hidden;
}

.overlay .dark {
    background: rgba(0, 0, 0, 0.85);
}

.overlay-full-height {
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;

    z-index: 100000;
    position: relative;

    overflow: hidden;
    overflow-y: scroll;

    background: #ffffff;
}

.overlay-small {
    position: relative;
    z-index: 100000;
    background: #ffffff;
    width: 100%;
    max-width: 550px;

    border-radius: 3px;
}

.overlay-minimal {
    position: relative;
    z-index: 100000;
    background: #ffffff;
    width: 100%;
    max-width: 900px;

    border-radius: 3px;
    overflow: hidden;
}

.overlay-functional {
    position: relative;
    z-index: 100000;
    background: #ffffff;
    min-width: 500px;
    max-width: 100vw;
    border-radius: 3px;
}

.overlay-dark {
    position: relative;
    z-index: 100000;
    background: rgba(0, 0, 0, 0.85);
    width: 700px;
    max-width: 100vw;
    border-radius: 8px;
    overflow: hidden;
}

.overlay-container-cdk {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    padding: 30px;
}

.overlay-container-cdk > .header {
    display: flex;
    flex-direction: row;

    margin-bottom: 25px;
}

.overlay-container-cdk .content {
    display: flex;
    flex-direction: column;
}

.detail-overlay {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
}

.dialog-overlay {
    min-width: 10vw;
    min-height: 10vh;
    //min-height: 50vh;
    max-height: 70vh;
    z-index: 10000;
    position: relative;

    border-radius: 10px;
    background: white;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

/**
 * Overrides
 */
.cdk-overlay-container {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100000;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.popover-backdrop {
    background: rgba(70, 65, 61, 0.6);
    scroll-behavior: unset;
}


.close-overlay-control {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
