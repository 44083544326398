@import "icons";

$button-height: 55px;
.iona-button {
    background: transparent;

    height: 55px;
    min-width: 280px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding-right: 28px;
    padding-left: 28px;
    margin: 0;

    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    border-radius: 28px;
    border: 2px solid white;

    text-decoration: none;
    text-align: center;
    font-size: 22px;
    font-family: "Innogy regular", sans-serif;

    color: white;

    &:hover {
        color: $accent-yellow;
    }

    &:focus {
        outline: none;
    }
}

.iona-button.scale {
    width: 100%;
}

.iona-button.white {
    background-color: white;
    color: $text-color;
    border-color: transparent;
}

.iona-button.orange {
    background-color: $iona-orange;
}

.iona-button.dark {
    background-color: #333;
    color: white;
    border-color: #333;
}

.iona-button.outline {
    height: 51px;
    margin: 2px;
    border-color: #333;
    color: #333;
    background-color: transparent;
    transition: color 250ms ease, border-color 250ms ease, background-color 250ms ease, opacity 250ms ease, box-shadow 250ms ease;
    -webkit-transition: color 250ms ease, border-color 250ms ease, background-color 250ms ease, opacity 250ms ease, box-shadow 250ms ease;

    &:hover {
        border-collapse: $iona-orange;
    }
}

.iona-button.small {
    height: 32px !important;
    border-radius: 16px;
    min-width: 100px;
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 18px;
}


/**
 * Round Icon Buttons
 */
$icon-button-size: 40px;
.iona-icon-button {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-left: 60px;

    border: 0;
    background-color: transparent;

    color: #333;
    @include font-bold;
    font-size: 18px;

    transition: color 100ms ease-in;

    &:before {
        content: "";
        float: left;
        height: $icon-button-size;
        width: $icon-button-size;
        margin-right: 10px;

        transition: background-color 100ms ease-in;
    }

    &:hover {
        color: $iona-orange;

        &:before {
            background-color: $iona-orange;
        }
    }
}


.iona-icon-button.add-tile {
    &:before {
        background-color: #333;
        mask-image: url("~src/assets/img/icons/add-tiles.png");
        mask-size: contain;
        mask-position: left;
        mask-repeat: no-repeat;
    }

    &:hover {
        color: $iona-orange;

        &:before {
            background-color: $iona-orange;
        }
    }
}

.iona-icon-button.sort-tiles {
    &:before {
        background-color: #333;
        mask-image: url("~src/assets/img/icons/sort-tiles.png");
        mask-size: contain;
        mask-position: left;
        mask-repeat: no-repeat;
    }

    &:hover {
        color: $iona-orange;

        &:before {
            background-color: $iona-orange;
        }
    }
}

/**
 * ICON BUTTON W/ ICON AFTER TEXT
 */

.iona-icon-button-after {
    height: 55px;
    width: 280px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding-left: 10px;
    padding-right: 10px;

    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    border-radius: 28px;
    border: 2px solid white;

    background-color: white;

    color: #333;
    @include font-bold;
    font-size: 18px;

    transition: color 100ms ease-in;

    &:after {
        content: "";
        float: left;
        height: $icon-button-size;
        width: $icon-button-size;

        transition: background-color 100ms ease-in;
    }

    &:hover {
        color: $iona-orange;

        &:after {
            background-color: $iona-orange;
        }
    }
}


.iona-icon-button-after.internal-link {
    &:after {
        background-color: #333;
        mask-image: url("~src/assets/img/icons/link.svg");
        mask-size: contain;
        mask-position: left;
        mask-repeat: no-repeat;
    }

    &:hover {
        color: $iona-orange;

        &:after {
            background-color: $iona-orange;
        }
    }
}

/**
 * Icon only buttons
 */
.iona-icon-only-button {
    display: flex;
    flex-direction: row;
    align-items: center;

    border: 0;
    background-color: #333;

    transition: background-color 100ms ease-in;

    &:hover {
        background-color: $iona-orange;
    }
}

.iona-icon-only-button.white {
    background-color: #fff;
}

.iona-icon-only-button.round {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: transparent;
}

/**
 * REGULAR PLUS MINUS
 */
.iona-icon-only-button.plus {
    @include icon('/assets/img/icons/plus.png', m, true);

    &.big {
        @include icon('/assets/img/icons/plus.png', xl, true);
    }

    &.white {
        background-color: white;

        &:hover {
            color: $iona-orange;
        }
    }
}

.iona-icon-only-button.minus {
    @include icon('/assets/img/icons/minus.png', m, true);

    &.big {
        @include icon('/assets/img/icons/minus.png', xl, true);
    }

    &.white {
        background-color: white;

        &:hover {
            color: $iona-orange;
        }
    }
}

/**
 * CLOSE / INFO BUTTONS
 */
.iona-icon-only-button.close {
    @include icon('/assets/img/icons/close.png', m, true);
}

.iona-icon-only-button.info {
    @include icon('/assets/img/icons/info.png', m, true);
}

.iona-icon-only-button.info-big {
    @include icon('/assets/img/icons/info.png', m, true);
}


/**
 * TIMEFRAME PLUS MINUS
 */
.iona-icon-only-button.timeframe-plus {
    @include icon('/assets/img/icons/timeframe-plus.png', m, true);
}

.iona-icon-only-button.timeframe-minus {
    @include icon('/assets/img/icons/timeframe-minus.png', m, true);
}

/**
 * PREV & NEXT
 */
.iona-icon-only-button.prev {
    @include icon('/assets/img/icons/arrow-right.png', m, true);
    transform: rotateZ(180deg);
}

.iona-icon-only-button.next {
    @include icon('/assets/img/icons/arrow-right.png', m, true);
}

/**
 * EDIT & SAVE
 */
.iona-icon-only-button.edit {
    @include icon('/assets/img/icons/edit.webp', l, false);
}

.iona-icon-only-button.edit.dark {
    @include icon('/assets/img/icons/edit-dark.png', l, false);
}

.iona-icon-only-button.save {
    @include icon('/assets/img/icons/save.webp', l, false);
}

/**
 * TRASH
 */
.iona-icon-only-button.trash {
    @include icon('/assets/img/icons/trash.png', l, true);
}



/**
 * Play Button
 */

.iona-icon-only-button.play {
    @include icon('/assets/img/icons/play.webp', m, true);
    background-color: white;
}
