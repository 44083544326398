$picker-height-small: 36px;


.date-picker-meter {
    height: $picker-height-small !important;
    border-radius: $picker-height-small / 2 !important;

    border: 2px solid #333 !important;
    background: white;

    &:hover {
        border-color: #F59B00 !important;
        color: #F59B00 !important;

        > label {
            color: #F59B00 !important;
        }
    }

    > label {
        color: #333 !important;
    }
}

.date-picker-today {
    border: 2px solid white !important;

    &:hover {
        border-color: #4C7327 !important;
        color: #4C7327 !important;

        > label {
            color: #4C7327 !important;
        }
    }

    > label {
        color: #fff !important;
    }
}


.date-picker-live {
    height: $picker-height-small !important;
    border-radius: $picker-height-small / 2 !important;

    border: 2px solid #333 !important;

    &:hover {
        border-color: #F59B00 !important;
        color: #F59B00 !important;

        > label {
            color: #F59B00 !important;
        }
    }

    > label {
        color: #333 !important;
    }
}
