@import "../fonts";

/// label + input = actual checkbox
/// ====================================================================================================================
label.iona-checkbox-alt {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 10px;

    font-size: 22px;
    @include font-bold;

    cursor: pointer;
}

label.iona-checkbox-alt > input[type="checkbox"] {
    appearance: none;
    width: 24px;
    height: 24px;

    margin-right: 15px;

    display: block;
    cursor: pointer;

    background-image: url("~src/assets/img/icons/unchecked-alt.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

/// checked
/// ====================================================================================================================
label.iona-checkbox-alt > input[type="checkbox"]:checked {
    background-image: url("~src/assets/img/icons/checked-alt.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}


/// disabled state
/// ====================================================================================================================
label.iona-checkbox-alt.disabled {
    cursor: not-allowed;
    opacity: 0.3;
    &input {
        opacity: 1;
    }
}
