// colors
$clr-inactive-tab-border: #d8d8d8;
$clr-inactive-tab-text: rgba(0, 0, 0, 0.4);
$clr-active-tab-text: #000;

// feature colors
$clr-household-comparison-active-tab: #64b42d;
$clr-electricity-rate-cap-active-tab: #009ba5;
$clr-comparison-active-tab: #f59b00;
$clr-appliances-active-tab: #c81e82;


/// tab bar general
/// ====================================================================================================================
div.tab-bar {
    display: flex;
    flex-direction: row;
}

div.tab-bar > div {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 8px;

    margin-top: 30px;

    border-bottom: 5px solid $clr-inactive-tab-border;
    color: $clr-inactive-tab-text;

    cursor: pointer;

    font-family: "Innogy Bold", sans-serif;

    user-select: none;
}

div.tab-bar > div.active {
    border-bottom: 5px solid $clr-household-comparison-active-tab;
    color: $clr-active-tab-text;
}

div.tab-bar > div.active > i {
    background-color: $clr-active-tab-text;
}

div.proxy-tab {
    flex: 1;
    cursor: unset !important;
}

div.spacer {
    width: 20px;
}


/// tab bar colors
/// ====================================================================================================================
div.tab-bar.household-comparison > div.active {
    border-bottom-color: $clr-household-comparison-active-tab;
}

div.tab-bar.comparison > div.active {
    border-bottom-color: $clr-comparison-active-tab;
}

div.tab-bar.appliances> div.active {
    border-bottom-color: $clr-appliances-active-tab;
}
