@import "../mixins/iona-scrollbar";

$household-comparison-thumb: #00875a;
$household-comparison-thumb-hover: #64b42d;

.scroll-container-vertical {
    overflow-y: auto;
    width: 100%;
}

.scroll-container-horizontal {
    overflow-x: auto;
    width: 100%;
}

.scroll-container-vertical.household-comparison {
    @include iona-scrollbar($household-comparison-thumb, $household-comparison-thumb-hover);
}
