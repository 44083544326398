$scrollbar-width: 5px;
$scrollbar-border-radius: calc(#{$scrollbar-width} / 2);

@mixin iona-scrollbar($thumb-color, $thumb-color-hover) {
    &::-webkit-scrollbar {
        width: $scrollbar-width;
    }

    &::-webkit-scrollbar-track {
        border-radius: $scrollbar-border-radius;
        background: #ececec;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $scrollbar-border-radius;
        background-color: $thumb-color;
        cursor: pointer;
        transition: background-color 400ms ease;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $thumb-color-hover;
        cursor: pointer;
        transition: background-color 400ms ease;
    }
}
